import React from 'react';
import slugify from 'react-slugify';
import { HLinkButton } from '../layout/headings';

const PulseHeader = ({ icon, title, smallText, hideCopy }) => {
  const anchor = slugify(title);
  return (
    <div
      id={anchor}
      className={`group flex items-center pb-4 w-full gap-2.5 ${
        smallText ? '' : 'border-b border-slate-200'
      }`}
    >
      {icon && (
        <img
          src={icon}
          className={`${smallText ? 'w-6 h-6' : 'w-8 h-8 sm:w-12 sm:h-12'}`}
          alt={title}
        />
      )}
      <span
        className={`text-brand-blue-5 capitalize ${
          smallText ? 'font-black text-base' : 'font-bold text-xl sm:text-4xl sm:font-black'
        }`}
      >
        {title}
      </span>
      {!hideCopy && <HLinkButton anchor={anchor} showOnRight={true} />}
    </div>
  );
};
export default PulseHeader;
