import React from 'react';
import useDetectPrint from '../../hooks/useDetectPrint';

const Doughnut = ({ value, negative, animate }) => {
  //  negative it reverses the green and yellow
  // if false: >=50  green
  // if true: >= 50 yellow
  const isPrinting = useDetectPrint();
  const strokeColor = negative
    ? value >= 50
      ? '#FEDC6B'
      : '#64D09C'
    : value >= 50
    ? '#64D09C'
    : '#FEDC6B';

  return (
    <svg
      viewBox="0 0 500 500"
      xmlns="http://www.w3.org/2000/svg"
      className="w-full h-full -rotate-90"
    >
      <path
        d="M 250 0 C 388.072 0 500 111.928 500 250 C 500 388.072 388.072 500 250 500 C 111.928 500 0 388.072 0 250 C 0 111.928 111.928 0 250 0 Z M 250 454.211 C 362.782 454.211 454.211 362.782 454.211 250 C 454.211 137.218 362.782 45.788 250 45.788 C 137.218 45.788 45.788 137.218 45.788 250 C 45.788 362.782 137.218 454.211 250 454.211 Z"
        fill="#F2F7FA"
      ></path>
      <ellipse
        className={`transform ease-[cubic-bezier(.1,.1,.25,.9)] origin-center duration-700 will-change-transform ${
          isPrinting ? 'transition-none' : 'transition-all'
        }`}
        fill="none"
        strokeWidth="44px"
        stroke={strokeColor}
        pathLength="1"
        strokeDashoffset="0px"
        strokeDasharray={`${animate ? value / 100 : 0}px 1px`}
        strokeLinejoin="round"
        strokeLinecap="round"
        cx="250"
        cy="250"
        rx="228"
        ry="228"
      ></ellipse>
    </svg>
  );
};
export default Doughnut;
