import React from 'react';
import arrowDecorator from '../../images/icons/icon-arrows-decorator.svg';

const StickyContent = ({ children }) => {
  return (
    <div className="flex flex-col p-5 sm:p-6 border border-brand-blue-3">
      <span className="text-brand-blue-5 text-xl font-bold mb-2.5">The GrowSF Take</span>
      <img src={arrowDecorator} width={40} height={12} className="mb-6" alt="Arrow Decorator" />
      <span className="text-brand-blue-5 text-sm sm:text-base prose prose-blue">{children}</span>
    </div>
  );
};
export default StickyContent;
