import React from 'react';

const QuestionHeader = ({ children, smallText }) => {
  return (
    <div className={smallText ? 'leading-4' : ''}>
      {!smallText && (
        <span className="text-gray-700 font-bold text-sm lg:text-lg">The question: </span>
      )}
      <span className={`text-gray-700 font-light ${smallText ? 'text-xs' : 'text-sm lg:text-lg'}`}>
        {children}
      </span>
    </div>
  );
};
export default QuestionHeader;
