import React from 'react';

const InfoItem = ({ title, text, textColor = '' }) => {
  return (
    <div className="flex flex-col gap-1.5">
      <span
        className={`text-left text-xs font-bold uppercase ${
          textColor ? textColor : 'text-gray-700'
        }`}
      >
        {title}
      </span>
      <div className="flex flex-col gap-1">
        <div className={`text-left text-sm font-light ${textColor ? textColor : 'text-gray-700'}`}>
          {text}
        </div>
      </div>
    </div>
  );
};
export default InfoItem;
