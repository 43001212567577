import React, { Fragment } from 'react';
import { graphql } from 'gatsby';

// Future! These templates are not ready yet
//import IssuesTemplate from '../templates/issues-template';
//import VoterGuideTemplate from '../templates/voter-guide-template';
import PersonTemplate from '../templates/person-template';

// Default template is representative of what this page formerly was
import DefaultTemplate from '../templates/default-template';
import PulseTemplate from '../templates/pulse-template';
import ContentTemplate from '../templates/content-template';

const MdxPage = ({
  location,
  data,
  data: {
    mdx: {
      frontmatter: { post_type },
    },
  },
}) => {
  const templates = {
    // we can enable these when they are ready
    // issues: <IssuesTemplate data={data} location={location} />,
    //'voter-guide': <VoterGuideTemplate data={data} location={location} />,
    pulse: <PulseTemplate data={data} location={location} />,
    person: <PersonTemplate data={data} location={location} />,
    campaigns: <ContentTemplate data={data} location={location} />,
    content: <ContentTemplate data={data} location={location} />,
  };

  return (
    <>
      {templates[post_type] ? (
        templates[post_type]
      ) : (
        <DefaultTemplate data={data} location={location} />
      )}
    </>
  );
};

export const query = graphql`
  query ($slug: String) {
    mdx(slug: { eq: $slug }) {
      tableOfContents
      body
      slug
      frontmatter {
        title
        sub_title
        intro_text
        election_name
        description
        post_type
        date(formatString: "MMMM DD, YYYY")
        date_modified(formatString: "MMMM DD, YYYY")
        # header_style
        show_lang
        used_langs
        donorbox_campaign
        donorbox_type
        donor_subtitle
        dateISO: date
        dateModifiedISO: date_modified
        featured_image_credit_link
        featured_image_credit
        featured_image {
          childImageSharp {
            gatsbyImageData
          }
        }
        hide_featured_image
        hide_top
        open_graph_image: featured_image {
          childImageSharp {
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
        is_pac
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

export default MdxPage;
