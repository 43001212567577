import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

const TopSection = ({
  title,
  subTitle,
  date,
  dateModified,
  heroImage,
  heroImageAlt,
  creditLink,
  creditLinkText,
}) => (
  <div>
    <div className="mt-6 my-4 sm:my-12 prose prose-blue sm:prose-lg mx-auto" data-top-section>
      <h1 className="!mb-2">{title}</h1>
      {subTitle ? <h2 className="text-gray-500 !m-0">{subTitle}</h2> : null}
      {date ? <h4 className="text-gray-500 !m-0 text-sm">Published {date}</h4> : null}
      {dateModified ? <h4 className="text-gray-500 !m-0 text-sm">Updated {dateModified}</h4> : null}
    </div>
    <div className="max-w-2xl mx-auto">
      {heroImage ? (
        <div className="md:-mx-20">
          <GatsbyImage
            image={heroImage}
            alt={heroImageAlt ? heroImageAlt : title}
            className="shadow-lg object-cover !mt-0 min-w-full"
          />
        </div>
      ) : null}
      {creditLink ? (
        <p className="mt-4 text-center text-base text-gray-400">
          Photo Credit:{' '}
          <a href={creditLink} className="underline">
            {creditLinkText}
          </a>
        </p>
      ) : (
        ''
      )}
    </div>
  </div>
);

export default TopSection;
