import React from 'react';

const Question = ({ children, smallText }) => {
  return (
    <div className={`flex flex-col ${smallText ? 'gap-1.5 w-full' : 'gap-3 sm:gap-5'}`}>
      {children}
    </div>
  );
};

export default Question;
