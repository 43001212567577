import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { getImage } from 'gatsby-plugin-image';

import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import shortcodes from '../components/mdxCommon';
import ContentIntro from '../components/contentIntro';
import Campaign from '../components/content/campaign';
import getLang from '../utils/getLang';

const ContentTemplate = ({ location = {}, data = {} }) => {
  const { mdx = {}, site = {} } = data;
  const {
    slug: slug,
    frontmatter: {
      post_type: postType,
      featured_image: featuredImage,
      hide_featured_image: hideFeaturedImage,
      open_graph_image: openGraphImage,
      sub_title: subTitleFromData,
      intro_text: introText,
      date_modified: dateModifiedFromData,
      title,
      description,
      hero_image_alt: heroImageAlt,
      show_lang: showLang,
      used_langs: usedLangs,
      donorbox_campaign: donorboxCampaign,
      donorbox_type: donorboxType,
      donor_subtitle: donorSubtitle,
      header_style: headerStyle,
    } = {},
    body,
  } = mdx;

  const heroImage = featuredImage && !hideFeaturedImage ? getImage(featuredImage) : null;
  const openGraphImageResized = openGraphImage?.childImageSharp.resize || null;
  const subTitle = subTitleFromData || null;
  const dateModified = dateModifiedFromData;
  const currentLang = getLang(slug);

  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        image={openGraphImageResized}
        pathname={location.pathname}
      />
      <div className="mt-10 px-5">
        <ContentIntro
          pathname={location.pathname}
          headerStyle={headerStyle}
          title={title}
          subTitle={subTitle}
          introText={introText}
          dateModified={dateModified}
          heroImage={heroImage}
          heroImageAlt={heroImageAlt}
          postType={postType}
          showLang={showLang}
          currentLang={currentLang}
          usedLangs={usedLangs}
        />

        <div
          className="content relative mx-auto mt-6 prose sm:prose-sm md:prose-base lg:prose-lg sm:mt-12 tracking-wide"
          data-js-body
        >
          <MDXProvider components={shortcodes}>
            <MDXRenderer>{body}</MDXRenderer>
          </MDXProvider>
        </div>
        {postType === 'campaigns' && (
          <Campaign
            campaignId={donorboxCampaign}
            type={donorboxType}
            currentLang={currentLang}
            donorSubject={donorSubtitle}
          />
        )}
      </div>
    </Layout>
  );
};

export default ContentTemplate;
