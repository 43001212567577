import React, { useRef } from 'react';
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver';
import useDetectPrint from '../../hooks/useDetectPrint';

const BarChart = ({
  title,
  direction = 'vertical',
  list = [],
  infoList = [],
  palette = [],
  fullSpace = false,
  chartInfo = false,
  bottomInfo = false,
  smallText = false,
}) => {
  const isPrinting = useDetectPrint();
  // used one generic component BarChart to handle all the types
  // verticall or horizontal >>  uses list
  // single or stacked >> uses stackedList for multiple child stacked bars
  const ref = useRef(null);
  const entry = useIntersectionObserver(ref, {
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  });
  const isVisible = !!entry?.isIntersecting || isPrinting;
  return (
    <div ref={ref} className="w-full flex flex-col gap-7 sm:flex-row">
      <div
        className={`relative flex gap-1.5 ${
          direction === 'vertical' ? 'flex-row h-64' : 'flex-col w-full'
        }`}
      >
        {title && <span className="text-sm text-gray-700 font-bold">{title}</span>}
        {list?.map((item, index) =>
          !item.stackedList ? (
            <div
              key={`BarChart_${index}`}
              className={`relative flex items-center gap-3 ${
                direction === 'vertical' ? 'flex-col justify-end h-full' : 'flex-row w-full'
              }`}
            >
              <span
                className={`relative rounded transform ease-[cubic-bezier(.1,.1,.25,.9)] transition-all origin-center duration-700 will-change-transform  ${
                  direction === 'vertical' ? `w-9 sm:w-14 mb-10` : `h-9`
                }`}
                style={{
                  background: palette[index] || item.bgColor,
                  height:
                    direction === 'vertical' &&
                    (isVisible ? `${fullSpace ? item.value * 2 : item.value}%` : '0%'),
                  width: direction != 'vertical' && (isVisible ? `${item.value}%` : '0%'),
                }}
              >
                <span
                  className={`absolute flex items-center gap-2 ${
                    direction === 'vertical'
                      ? 'lg:-mb-10 -mb-8 bottom-0 left-1/2 -translate-x-1/2 flex-col'
                      : 'top-1/2 left-2.5 -translate-y-1/2'
                  }`}
                >
                  <span
                    className={`text-xs font-black sm:text-lg ${
                      isVisible
                        ? 'opacity-100 w-max'
                        : 'opacity-0 w-0 print:opacity-100 print:w-max'
                    }`}
                    style={{ color: item.valueColor ? item.valueColor : '#1C3837' }}
                  >
                    {item.value}%
                  </span>
                  {item.textIn && (
                    <span
                      className={`${
                        direction === 'vertical' && '-rotate-45 '
                      }text-sm text-gray-700 font-light whitespace-nowrap transform ease-[cubic-bezier(.1,.1,.25,.9)] transition-all origin-center duration-1000 will-change-transform lg:text-lg print:transition-none`}
                    >
                      {item.textIn}
                    </span>
                  )}
                </span>
              </span>
              {item.textOut && (
                <span className="text-sm text-gray-700 font-light lg:text-lg">{item.textOut}</span>
              )}
            </div>
          ) : (
            <div key={`BarChart_stackedList_Parent_${index}`} className="flex flex-col gap-1.5">
              {item.title && (
                <span className="text-sm text-gray-700 font-bold mt-1.5">{item.title}</span>
              )}
              <div
                className={`flex w-full gap-3 ${
                  direction === 'vertical' ? 'flex-col justify-end h-full' : 'flex-row w-full'
                }`}
              >
                {item.stackedList.map((inner, index) => (
                  <div
                    key={`BarChart_stackedList_${index}`}
                    className={`relative flex items-center rounded transform ease-[cubic-bezier(.1,.1,.25,.9)] transition-all origin-center duration-700 will-change-transform print:transition-none ${
                      direction === 'vertical' ? `w-14` : `h-9`
                    }`}
                    style={{
                      background: inner.bgColor,
                      height:
                        direction === 'vertical' &&
                        (isVisible ? `${fullSpace ? inner.value * 2 : inner.value}%` : '0%'),
                      width: direction != 'vertical' && (isVisible ? `${inner.value}%` : '0%'),
                    }}
                  >
                    <span
                      className={`absolute flex items-center ${
                        direction === 'vertical'
                          ? 'bottom-0 left-1/2 -translate-x-1/2'
                          : smallText
                          ? 'gap-1 top-1/2 left-1.5 -translate-y-1/2'
                          : 'gap-2 top-1/2 left-2.5 -translate-y-1/2'
                      }`}
                    >
                      <span
                        className={`text-xs font-black ${
                          isVisible
                            ? 'opacity-100 w-max'
                            : 'opacity-0 w-0 print:opacity-100 print:w-max'
                        } ${smallText ? '' : 'sm:text-lg'}`}
                      >
                        {inner.value}%
                      </span>
                      {inner.textIn && (
                        <span
                          className={`text-gray-700 font-light whitespace-nowrap transform ease-[cubic-bezier(.1,.1,.25,.9)] transition-all origin-center duration-1000 will-change-transform print:transition-none ${
                            smallText ? 'text-[10px]' : 'text-xs'
                          }`}
                        >
                          {inner.textIn}
                        </span>
                      )}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )
        )}
      </div>
      {chartInfo && (
        <div
          className={`flex flex-col gap-3 w-max flex-shrink-0 ${
            bottomInfo ? 'justify-end' : 'justify-center'
          }`}
        >
          {infoList?.map((item, index) => (
            <div className="flex gap-1.5" key={`BarChart_${index}`}>
              <span
                className={`flex gap-1.5 rounded transform ease-[cubic-bezier(.1,.1,.25,.9)] transition-all origin-center duration-700 will-change-transform print:transition-none ${
                  isVisible ? 'opacity-100' : 'opacity-0 print:opacity-100'
                }`}
                style={{
                  transitionDelay: `${index * 0.2}s`,
                }}
              >
                <span
                  className="w-5 h-5 flex-shrink-0 rounded"
                  style={{
                    background: item.bgColor || item.bgColorInfo,
                  }}
                ></span>
                <span
                  className="text-xs font-bold [&_b]:text-sm [&_b]:font-black"
                  dangerouslySetInnerHTML={{ __html: item.textInfo }}
                />
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default BarChart;
