import React from 'react';

const List = ({ title, list, type = 'disc', textColor = '', upperCase }) => {
  return (
    <div className="flex flex-col gap-4">
      <span
        className={`text-left text-xs uppercase font-bold ${
          textColor ? textColor : 'text-gray-700'
        }`}
      >
        {title}
      </span>
      <div className={`flex flex-col gap-1 pl-5 ${type === 'disc' ? 'list-disc' : 'list-decimal'}`}>
        {list?.map((item, index) => (
          <div
            key={`Pulse_list_${index}`}
            className={`text-left text-sm font-light list-item ${
              textColor ? textColor : 'text-gray-700'
            }`}
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};
export default List;
