import React from 'react';
import iconQuote from '../../images/icons/quote.svg';

export const Quote = ({ children }) => {
  return (
    <div className="not-prose relative my-4 md:my-14">
      <img
        src={iconQuote}
        alt=""
        className="w-[40px] h-[40px] md:w-[80px] md:h-[80px] absolute -z-10 -left-2 -top-2 md:-left-4 md:-top-10"
      />
      <blockquote className="text-brand-blue-4 text-sm sm:text-lg font-light tracking-wide">
        {children}
      </blockquote>
    </div>
  );
};
