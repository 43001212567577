import React from 'react';
import { Helmet } from 'react-helmet';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { getImage } from 'gatsby-plugin-image';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import TopSection from '../components/topSection';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import ThreeColumn from '../components/layout/three-column';
import TwoColumn from '../components/layout/two-column';
import Table from '../components/layout/table';
import { H1, H2 as baseH2, H3, H4, H5, H6 } from '../components/layout/headings';
import { Quote } from '../components/layout/quote';
import { NewTabLink } from '../components/newtablink';
import { SignupForm } from '../components/signup';
import ScrollSpy from '../components/scrollSpy';

const H2 = ({ idOverride, children, className, ...props }) => {
  const { arrowDecorator = true } = props;
  return baseH2({ idOverride, children, className, arrowDecorator, ...props });
};

const PersonTemplate = ({ location = {}, data = {} }) => {
  const { search = '' } = location;
  const { mdx = {}, site = {} } = data;

  const {
    slug: slug,
    frontmatter: {
      is_pac: isPACFromData,
      featured_image: featuredImage,
      hide_featured_image: hideFeaturedImage,
      hide_top: hideTop,
      open_graph_image: openGraphImage,
      featured_image_credit_link: creditLinkFromData,
      featured_image_credit: creditLinkTextFromData,
      sub_title: subTitleFromData,
      date,
      date_modified: dateModifiedFromData,
      title,
      description,
      hero_image_alt: heroImageAlt,
    } = {},
    body,
  } = mdx;

  const heroImage = featuredImage && !hideFeaturedImage ? getImage(featuredImage) : null;
  const showTop = !hideTop;
  const openGraphImageResized = openGraphImage?.childImageSharp.resize || null;
  const creditLink = creditLinkFromData || null;
  const creditLinkText = creditLinkTextFromData || null;
  const subTitle = subTitleFromData || null;
  const dateModified = dateModifiedFromData;

  const shortcodes = {
    ThreeColumn,
    TwoColumn,
    Table,
    NewTabLink,
    ScrollSpy,
    Helmet,
    SignupForm,
    OutboundLink,
    h1: H1,
    h2: H2,
    h3: H3,
    h4: H4,
    h5: H5,
    h6: H6,
    blockquote: Quote,
  };
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        image={openGraphImageResized}
        pathname={location.pathname}
      />
      <div className="mt-10 mb-20 px-5">
        {showTop ? (
          <TopSection
            title={title}
            subTitle={subTitle}
            date={date}
            dateModified={dateModified}
            heroImage={heroImage}
            heroImageAlt={heroImageAlt}
            creditLink={creditLink}
            creditLinkText={creditLinkText}
          />
        ) : (
          ''
        )}

        <div
          className="default mt-6 sm:mt-12 prose prose-blue sm:prose-lg mx-auto relative tracking-wide"
          data-js-body
        >
          <MDXProvider components={shortcodes}>
            <MDXRenderer>{body}</MDXRenderer>
          </MDXProvider>
        </div>
      </div>
    </Layout>
  );
};

export default PersonTemplate;
